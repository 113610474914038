import React, {Fragment, useState, useEffect, useRef} from "react";
var colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF"
];

const addDefaultSrc = (ev, user, index) => {
  // alert(name)
  if (user && user.name && ev.target.innerHTML) {
    const myStyle = {
      height: 40,
      width: 40,
      borderRadius: 100,
      padding: 5,
      backgroundColor: "red"
    };
    // ev.target = "https://varefiles.s3.us-east-2.amazonaws.com/icon.png"
    ev.target.outerHTML =
      `<div` +
      ` style="` +
      `background-color:${
        index ? colorArray[index + 1].toString() + ";" : "#f2f3f5;"
      }` +
      `height:40px;` +
      `width:40px;` +
      `border-radius:100px;` +
      `padding:3px;` +
      `padding-left:15px;` +
      `font-weight:bold;` +
      `font-size:20px` +
      // align-items: center,
      // justify-content: center
      `">` +
      `${user.name.charAt(0)}</div>`;
    Object.keys(myStyle).map(res => {
      ev.target[res] = myStyle[res];
    });
  } else {
    ev.target.src = "https://varefiles.s3.us-east-2.amazonaws.com/icon.png";
  }
};

export default addDefaultSrc;
