import React, {Fragment, useState, useEffect, useRef} from "react";
import {
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggl,
  Container,
  Col,
  Media,
  FormGroup,
  Progress,
  CardTitle,
  Label,
  Button
} from "reactstrap";
import {Formik, Form, Field} from "formik";
import UserAvatar from "react-user-avatar";
import Select from "react-select";
import CustomSelectInput from "../../mycomponents/common/CustomSelectInput";
import "./select.css";
import ImageUploader from "react-images-upload";
import ContentModal from "./ContentModal";
import CommentsMobile from "./CommentsMobile";
import PageMenu from "./PageMenu";
import MediaCard from "./MediaCard";
import PageFooter from "./PageFooter";
// import {Helmet} from "react-helmet";
import cookie from "react-cookies";
import PlaceholderMessage from "./PlaceholderMessage";

import {useHistory} from "react-router-dom";

import VideoConfRoom from "./VideoConfRoom";
import HoverVideoPlayer from "react-hover-video-player";
import ScrollMenuPills from "./ScrollMenuPills";
import TextInput from "react-autocomplete-input";
import ReactDOM from "react-dom";
import Modal from "react-modal";

import moment from "moment";

// import ReactPlayer from 'react-player/youtube'
import ReactPlayer from "react-player";
import "../../style.css"; // Tell webpack that Button.js uses these styles
import CommentsBlock from "simple-react-comments";

import "../../info.css";
import "../../../../assets/css/sass/_gogo.style.scss";
import styled from "styled-components";

import io from "socket.io-client";

import Loader from "react-loader-spinner";

import {Browserdiv, Mobilediv, isBrowser, isMobile} from "react-device-detect";

import {Colxx, Separator} from "../../mycomponents/common/CustomBootstrap";

import {ThemeColors} from "../../helpers/ThemeColors";
import {groupBy} from "../../helpers";

//Import Section Title

import Axios from "axios";

import useLocalStorage from "./localStorage";

// import "react-slideshow-image/dist/styles.css";
const colors = ThemeColors();

const map = require("../../../../assets/images/features/map.png");
const pics = require("../../../../assets/images/04.jpg");
const pics5 = require("../../../../assets/images/05.jpg");
const tempVideo = require("../../../../assets/images/video.mp4");
const StyledVideo = styled.video`
  /* flexDirection:'row', */
  height: 50%;
  width: 45%;
`;

const slideImages = [
  "images/slide_2.jpg",
  "images/slide_3.jpg",
  "images/slide_4.jpg"
];

var audio = new Audio(
  "https://varefiles.s3.us-east-2.amazonaws.com/bgmusic.mp3"
);

const Slide = props => {
  return (
    <div style={{position: "absolute", width: "100%", left: -10}}>
      {props.children}
    </div>
  );
};

const PodcastModal = ({
  setLoginUser,
  loginUser,
  info,
  onHandleQuery
  // contents,
  // meetingKey,
  // host,
  // meetingId,
  // height,
  // width,
  // setUser,
  // saveComment,
  // updateComment,
  // messages
}) => {
  const [videos, setVedeos] = useState([1, 2, 3, 4]);
  const [playIndex, setPlayIndex] = useState(-1);

  const MediaCard = ({data, index, setPlayIndex, playIndex}) => {
    const [play, setPlay] = useState(false);
    const [myIndex, setMyIndex] = useState(0);

    useEffect(() => {
      if (play) {
        audio.play();
      } else {
        audio.pause();
      }
      // console.log("playIndex", playIndex, play);
    }, [play]);

    var myDate = new Date();

    const Scripture = props => {
      return (
        <div
          style={{
            top: 150,
            // width: "100%",
            left: "15%",
            position: "absolute",
            zIndex: 6,
            padding: 10,
            backgroundColor: "rgba(0,0,0,.54)",
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <div>
            <h2 style={{color: "green"}}>Proverbs 6:20 </h2>
          </div>
          <div
            style={{
              fontWeight: "bold",
              width: 150,
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            My son, keep thy father;s commandment, and forsake not the law of
            thy mother.
          </div>
        </div>
      );
    };

    return (
      <Colxx key={"jghfgft" + index} sm={4} lg={4}>
        <div
          key={"jghfgjjhft" + index}
          style={{
            // maxMidth: 250,
            // minWidth: 300,
            display: "flex",
            flexWrap: "wrap",
            // marginRight: -15,
            // marginLeft: -15,
            marginTop: 100
            // height: 600
            // paddingBottom: 50
          }}
          className="h-100 row"
        >
          <div className="mx-auto my-auto col-12 col-md-10">
            <div
              style={{
                width: 350,
                marginRight: "10%",
                marginLeft: "10%",
                backgroundColor: "rgba(0,0,0,.54)",
                // backgroundColor: "black",
                borderRadius: 5,
                height: 400,
                paddingBottom: 10
              }}
            >
              <div
                style={{
                  background:
                    "url(/assets/img/login/balloon-lg.jpg) no-repeat center center fixed",
                  // backgroundColor: "rgba(0,0,0,.54)",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  height: 100,
                  maxMidth: 250,
                  width: "100%",
                  padding: 20,
                  color: "white"
                }}
              >
                <div
                  style={{
                    top: 30,
                    position: "absolute",
                    zIndex: 8,
                    display: "flex",
                    flexDirection: "row",
                    width: "100%"
                  }}
                >
                  <div
                    style={{marginRight: 15}}
                    onClick={() => {
                      // alert(3);
                      window.open(
                        "https://varefiles.s3.us-east-2.amazonaws.com/bgImages/bgimages/deemain.jpg",
                        "_blank"
                      );
                    }}
                  >
                    <img
                      style={{
                        height: 60,
                        width: 60,
                        borderRadius: 100
                      }}
                      src={
                        "https://varefiles.s3.us-east-2.amazonaws.com/bgImages/bgimages/dee.jpg"
                      }
                      alt=""
                      width={"100%"}
                    />
                  </div>

                  <i
                    onClick={() => {
                      setPlay(true);
                    }}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "red",
                      marginRight: 15
                    }}
                    className={`fas fa-play`}
                  ></i>
                  <i
                    onClick={() => {
                      setPlay(false);
                    }}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "red",
                      marginRight: 15
                    }}
                    className={`fas fa-stop`}
                  ></i>
                  <i
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "red",
                      marginRight: 15
                    }}
                    className={`fas fa-comments`}
                  >
                    {" "}
                  </i>
                </div>
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: 120,
                      zIndex: 11,
                      height: 30,
                      width: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 14
                    }}
                    className=""
                  ></div>
                </div>

                <div
                  style={{
                    top: 25,
                    // left: -10,
                    width: 330,
                    alignItems: "center",
                    // backgroundColor: "black",
                    // opacity: 0.5,
                    // top: 50,
                    // right: -14,
                    zIndex: 5,
                    // paddingLeft: -30,
                    // paddingRight: -30,
                    position: "absolute"
                    // marginRight: 40,
                    // maxMidth: 240
                    // marginBottom: 5
                  }}
                  className="slide-container"
                >
                  <Scripture />
                  <Slide>
                    {videos.map((rep, id) => {
                      return (
                        <div
                          style={{
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 5,
                            width: "100%"
                            // height: 450
                          }}
                          className="each-slide"
                        >
                          <i
                            onClick={() => {
                              if (myIndex * 1 - 1 >= 0)
                                setMyIndex(myIndex * 1 - 1);
                            }}
                            style={{
                              position: "absolute",
                              top: 100,
                              left: -20
                            }}
                            className={`fas fa-2x fa-chevron-circle-left`}
                          >
                            {" "}
                          </i>
                          <i
                            onClick={() => {
                              if (myIndex * 1 + 1 < videos.length)
                                setMyIndex(myIndex * 1 + 1);
                            }}
                            style={{
                              position: "absolute",
                              top: 100,
                              right: -15
                            }}
                            className={`fas fa-2x fa-chevron-circle-right`}
                          >
                            {" "}
                          </i>
                          {myIndex == id ? (
                            <div
                              style={{
                                // width: 200,
                                borderRadius: 5,
                                height: 450,
                                background: `url(https://varefiles.s3.us-east-2.amazonaws.com/church/${rep}.jpg) no-repeat center center`
                              }}
                            ></div>
                          ) : null}
                        </div>
                      );
                    })}
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Colxx>
    );
  };

  return (
    <React.Fragment>
      <Row
        style={{
          flexWrap: "wrap"
        }}
      >
        {videos.map((real, index) => {
          return (
            <MediaCard
              playIndex={playIndex}
              setPlayIndex={setPlayIndex}
              data={real}
              index={index}
            />
          );
        })}
      </Row>
    </React.Fragment>
  );
};

const styleInfo = {
  wrapPadMydiv: {
    margin: 10,
    paddingTop: 60,
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  wrapMydiv: {
    fontSize: 10,
    color: "#1c1e21",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  }
};

export default PodcastModal;
