import React, {Fragment, useState, useEffect, useRef, Image} from "react";
import {
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggl,
  Container,
  Col,
  Media,
  FormGroup
} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {useLocation, Link} from "react-router-dom";
import {ExternalLink} from "react-external-link";
import addDefaultSrc from "./addDefaultSrc";
import PageMenu from "./PageMenu";
import PageFooter from "./PageFooter";
import UserAvatar from "react-user-avatar";

import RESTCall from "../../../../redux/actions/restApi";
import {parseURL} from "../../helpers/Utils";
import {useHistory} from "react-router-dom";

import ReactDOM from "react-dom";
import Modal from "react-modal";

// import ReactPlayer from 'react-player/youtube'
import ReactPlayer from "react-player";
import "../../style.css"; // Tell webpack that Button.js uses these styles
import CommentsBlock from "simple-react-comments";

import "../../info.css";
import styled from "styled-components";

import io from "socket.io-client";

import Loader from "react-loader-spinner";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import {Colxx, Separator} from "../../mycomponents/common/CustomBootstrap";
import Doughnut from "../../mycomponents/charts/Doughnut";

//Import Section Title
import UserProfile from "./UserProfile";
import ActiveUserProfile from "./ActiveUserProfile";
import SummaryDetailCard from "./SummaryDetailCard";
import CommentsMobile from "./CommentsMobile";
import VideoConfRoom from "./VideoConfRoom";
import ImageUploader from "react-images-upload";
import Axios from "axios";

import useLocalStorage from "./localStorage";

const map = require("../../../../assets/images/features/map.png");
const pics = require("../../../../assets/images/04.jpg");
const pics5 = require("../../../../assets/images/05.jpg");
const tempVideo = require("../../../../assets/images/video.mp4");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const Home = ({setLoginUser, loginUser}) => {
  const history = useHistory();
  const [storeUser, setStoreUser] = useState(
    window.localStorage && window.localStorage.user
      ? {
          user: JSON.parse(window.localStorage.user)
        }
      : {}
  );
  const [meetingDetails, setMeetingDetails] = useState("");
  const [meetingId, setMeetingId] = useState("");

  useEffect(() => {
    // console.log("dddss", storeUser, meetingDetails);
    handleMeetingDetails();
  }, []);

  const handleMeetingDetails = async () => {
    const urlParams = parseURL(history.location.search);
    const formData = {
      request: "get",
      resource: "vare_meetings"
    };
    RESTCall.axiosQuery(formData).then(res => {
      if (res && res.data) {
        // console.log("formData", res.data.data);
        if (res.data && res.data.data) setMeetingDetails(res.data.data);
      }
    });
  };

  return (
    <div>
      <PageMenu />
      <div
        style={{
          paddingTop: 90,
          width: "100%"
          // alignItems: "center",
          // justifyContent: "center"
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 20
          }}
        >
          Vare Scheduled Meetings
        </div>
        {meetingDetails && meetingDetails.length > 0
          ? meetingDetails.map((res, index) => {
              // console.log("res", res);
              return (
                <div key={index + "dsssxsxsxsx"}>
                  <Card
                    onClick={() => {
                      setLoginUser("/");
                      history.push(`/?id=${res.meeting_id}`);
                    }}
                    style={{
                      backgroundColor: "#f2f3f5",
                      flexWrap: "wrap",
                      flexDirection: "row"
                      // color: "white"
                    }}
                  >
                    <div>
                      <UserAvatar
                        style={{
                          padding: 6,
                          color: "white"
                        }}
                        size={50}
                        name={res.author ? res.author : "Private"}
                        src={res.img}
                      />
                    </div>
                    <div
                      style={{
                        padding: 6,
                        fontSize: 10
                        // flexDirection: "row"
                      }}
                    >
                      <div>{`Author: ${res.author}`}</div>
                      <div>{`Title: ${res.title}`}</div>
                      <div>{`Meeting Id: ${res.meeting_id}`}</div>
                    </div>
                  </Card>
                </div>
              );
            })
          : null}
      </div>
      <PageFooter />
    </div>
  );
};

const styleInfo = {
  wrapPadMyText: {
    margin: 10,
    paddingTop: 60,
    fontSize: 10,
    color: "white",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  },
  wrapMyText: {
    fontSize: 10,
    color: "white",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto"
  }
};

export default Home;
